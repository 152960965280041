import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

    connect() {
        console.log('access controller connected')
        this.csrf = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
        this.id = this.element.getAttribute('data-info');
        this.card = this.element.getAttribute('card');
    }

    async handle_click() {
        this.toggle = this.element.querySelector('button[role="switch"]').getAttribute("value")
        if (this.toggle == "true") {
            await this.create()
        } else {
            await this.destroy()
        }

    }

    async toggle_all() {
        let elems = document.querySelectorAll('.access-card')
        for (let i = 0; i < elems.length; i++) {
            let state = elems[i].querySelector('button[role="switch"]').getAttribute("value")
            if (state == "false") {
                elems[i].querySelector('button[role="switch"]').click()
            }
        }
    }

    async create() {
        let select = this.element.querySelector("#tariff-select")
        let tariff_id = select.options[select.selectedIndex].getAttribute("value")
        const request = new Request(window.location.href , {
            method: "POST",
            headers: {
                'X-CSRF-Token': this.csrf,
                'Content-Type': 'application/json',
                'Accept': 'text/vnd.turbo-stream.html'
            },
            body: JSON.stringify({ 
                site_id: this.id,
                uid_card_id: this.card,
                tariff_id: tariff_id,
            }),
          });
        const response = await fetch(request);

    }

    async destroy() {
        // we don t need to send the authorization id
        const request = new Request(window.location.href + '/id' , {
            method: "DELETE",
            headers: {
                'X-CSRF-Token': this.csrf,
                'Content-Type': 'application/json',
                'Accept': 'text/vnd.turbo-stream.html'
            },
            body: JSON.stringify({ 
                site_id: this.id,
                uid_card_id: this.card,
            }),
          });
        const response = await fetch(request)
    }
}
